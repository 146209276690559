<div class="SubHeader FadIn">
    <h3 class="text-center mb-4">The four stages in our financial life:</h3>
</div>
<section style="padding: 0;">
    <div class="container">
        <div class="row">

            <div class="col-md-6" style="margin-top: 100px;">
                <div class="sub_title">
                    <h5> 1: The Fun Stage</h5>
                </div>
                <p>The fun stage usually is between ages 20 to 30. This is the stage where people just graduated and would want to enjoy few years to compensate for the years of hard work during the schooling years. Everyone is excited with their careers
                    and their first paycheck. They spend here, food trip there, travel here and new gadgets everywhere. Their favorite motto, “you only live once” a.k.a. Y.O.L.O. Saving and learning about investing are never a priority. Are you one of
                    them?
                </p>
            </div>
            <div class="col-md-6">
                <div class="funImg">
                    <img src="../../assets/images/FunImg.png" alt="Fun Images">
                </div>
            </div>
            <div class="col-md-12 sub_title mt-5">
                <h5> 2: The Married Stage.</h5>
            </div>
            <div class="col-md-6">
                <div class="maried_stage_img">
                    <img src="../../assets/images/mariedStage.png" alt="">
                </div>
            </div>
            <div class="col-md-6 mt-5">
                <p>From ages 30 to 40. After years of fun, a lot of couples in the late 20’s or early 30’s would then want to settle down. They will be preparing for one of the biggest expenses in their lives, the wedding day! Saving might then be a priority,
                    but saving to spend a chunk of money in one big day. While some never develop the habit of saving, they may even resort to go into debt just to spend a lot for a grand wedding day. After the wedding day, expenses will almost never
                    seem to cease as one might then think of creating a family, raising a child, finding their dream houses and buy their own cars. Everyone has the reason not to save and invest for the future.</p>
            </div>
            <div class="col-md-6" style="margin-top: 57px;">
                <div class="sub_title mt-5">
                    <h5> 3: The Education Stage</h5>
                </div>
                <p>From ages 40 to 50. This stage the children have grown up, getting ready for higher education relating to higher tuition fees. Parents now are juggling expenses, the mortgages of their houses or cars are taking a big chunk in their budgets.
                    College (the biggest investment for our children) is just around the corner.</p>
            </div>
            <div class="col-md-6">
                <div class="EducationImg">
                    <img src="../../assets/images/Education.png" alt="Education image">
                </div>
            </div>
            <div class="col-md-6">
                <div class="sub_title mt-5">
                    <h5> 4: The Health-Conscious Stage</h5>
                </div>
                <p>From ages 50 to 60. This stage, due to the stress in work or business, our body will start to demand from us through sickness and illness. Muscle pains here, knees starts to swell and moving seems to be very uncomfortable. Medicines are
                    for maintenance, and expenses still seem to be forever.</p>
                <p>Our productive lives end at stage 4 as we retire now at age 60 and beyond. When we look back, we never were able to set aside money for ourselves, we were never able to create wealth. That’s why a lot of Filipinos retire broke. Truly when
                    we look back, the best time to save and learn about investing is during our younger years.</p>
                <p>What stage we are right now in our productive financial lives, the best time to save was yesterday. The second best time is today! Tomorrow will be the worst time. Life is a choice: sacrifice now and enjoy later or enjoy now and suffer
                    later. The choice is yours to make.</p>
            </div>
            <div class="col-md-6">
                <div class="Health_Conscious_img">
                    <img src="../../assets/images/Health-Conscious Stage.png" alt="">
                </div>
            </div>
            <div class="col-md-12">
                <p>Meeting the financial challenges of today’s consumers requires a unique mix of experience and expertise. Backed by a wealth of resources, Cadre Financial is uniquely positioned to meet these challenges.
                </p>
                <p>As an independent financial services company, Cadre Financial is able to bring together the best products and companies to deliver unparalleled financial solutions to its clients.</p>
            </div>
        </div>

        <!-- <div class="row">
            <div class="col-md-4">
                <img src="./../../assets/images/productbusiness.jpg" class="product_img" alt="product business">
            </div>
            <div class="col-md-4">
                <div class="P_card">
                    <div class="list_title text-center">
                        Insurance Products
                    </div>
                    <ul class="list_">
                        <li>Indexed Universal Life</li>
                        <li>Term Life</li>
                        <li>Guaranteed Universal Life</li>
                        <li>Indexed Annuities</li>
                        <li>Fixed Annuities</li>
                        <li>Final Expense</li>
                    </ul>
                </div>
            </div>
            <div class="col-md-4">
                <div class="P_card">
                    <div class="list_title text-center">
                        Referral Products​
                    </div>
                    <ul class="list_">
                        <li>Indexed Universal Life</li>
                        <li>Term Life</li>
                        <li>Guaranteed Universal Life</li>
                        <li>Indexed Annuities</li>
                        <li>Fixed Annuities</li>
                        <li>Final Expense</li>
                    </ul>
                </div>
            </div>
        </div> -->
    </div>
</section>