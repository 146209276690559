import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './services.component.html',
})
export class ServicesComponent implements OnInit {

  ngOnInit() {
 
  }
}
