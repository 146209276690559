import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'cadre-us-app',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {

  ngOnInit() {
 
  }
}
