import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-financial-house',
  templateUrl: './new-financial-house.component.html',
  styleUrls: ['./new-financial-house.component.css']
})
export class NewFinancialHouseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
