import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'cadre-us-app',
  templateUrl: 'Financialcalculater.component.html',
  styleUrls: ['Financialcalculater.component.css']
})
export class FinancialcalculaterComponent implements OnInit {

  ngOnInit() {
 
  }
}
