import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cadre-us-app',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  ngOnInit() {

  }

  showpopup: boolean = false;
  open() {

    this.showpopup = true;
  }
  closePopup(){
    this.showpopup = false;
  }
}
