<div class="SubHeader FadIn">
    <h3 class="text-center ">Wealth Formula</h3>
</div>
<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12 mb-5">
                <div class="row">
                    <div class="col-md-4">
                        <div class="financial_img">
                            <img src="./../../assets/images/WealthFormula.png" alt="">
                        </div>
                    </div>
                    <div class="col-md-8 mt-4">
                        <div class="mt-4">
                            <p class="mt-4">It is good to understand the right formula in saving money. However, saving is not enough. One must understand that in order to build wealth, a powerful concept must be applied, the wealth formula.</p>
                        </div>
                        <div class="mt-4">
                            <h3>What is the wealth formula?</h3>
                            <p>The wealth formula states: money (+) time (+/-) rate of return (-) inflation and (-) tax = wealth. Let’s define each element.</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12">
                <h5>(+) Money</h5>
                <p>No one becomes wealthy without money. Therefore, money is the number 1 ingredient in building wealth. This money can come from your income, savings or investments. However, money alone cannot make you wealthy; it requires accounting the
                    other elements.</p>
            </div>
            <div class="col-md-12 mt-4">
                <h5>(+) Time</h5>
                <p>We believe that building wealth takes time, not a get-rich-quick scheme. Buying a property now and selling it tomorrow earning 10x your capital is impossible. Even playing the lottery takes time, people already died betting without winning.
                    Nobody who bets for the first time and wins! Unless you have some kind of a miracle ointment.</p>
            </div>
            <div class="col-md-12 mt-4">
                <h5>(+/-) Rate of return</h5>
                <p>This shows the interest rate your money is growing. However, it is positive if the interest is an investment and negative if the interest is applied on debt.</p>
                <p>Example: 10K invested in 10% interest per year, next year your money grows to 11K. But if you have a 10K debt from a lender offering 6% interest per month! you enjoyed 10K now but next month you need to pay lender with interest.</p>
            </div>
            <div class="col-md-12 mt-4">
                <h5>(-) Inflation</h5>
                <p>This is the rate of increase in the prices of commodities. In the United States, a 10-year average inflation rate is 3% per year. This means that the value of commodities increases by 3% of their current price. This shows a negative value
                    in your wealth as inflation reduces the buying power of your money every year, guaranteed!</p>
            </div>
            <div class="col-md-12 mt-4">
                <h5>(-) Tax</h5>
                <p>They say change is the only permanent thing in the world, now, so is tax. From womb to tomb, there will always be tax. It takes a chunk in wealth building thus it is a negative.</p>
                <p>Adding all the elements: money (+) time (+/-) rate of return (-) inflation (-) tax, whatever is left is your wealth. This formula is so powerful, once applied can guarantee us to be build wealth. One must therefore take the time to learn
                    and acquire the needed skills to build wealth: learn to increase cashflow, have the patience to wait, understand different investment options that give better rates of return to beat inflation and know basic strategies to minimize
                    (not evade) paying taxes.</p>
                <p>It is important to note that wealth is subjective. One must define what wealth means.</p>
            </div>
        </div>
    </div>
</section>