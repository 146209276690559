import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component'
import { ContactComponent } from './Contact/contact.component'
import { FinancialHouseComponent } from './FinancialHouse/financialhouse.component'
import { ProductsComponent } from './Products/products.component'
import { WealthFormulaComponent } from './WealthFormula/wealthformula.component'
import { opportunityComponent } from './Opportunity/opportunity.component'
import { payyourselffirstComponent } from './Didyouknow/payyourselffirst.component'
import { RuleofComponent } from './RuleOf/ruleof.component'
import { FinancialcalculaterComponent } from './FinancialCalculators/Financialcalculater.component'
import { NewFinancialHouseComponent } from './new-financial-house/new-financial-house.component'
import { XcurveComponent } from './xcurve/xcurve.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { PrivacyPolicyComponent } from './privacypolicy/privacypolicy.component';
import { ServicesComponent } from './services/services.component';
import { CoreConceptsComponent } from './CoreConcepts/coreconcepts.component';
const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'product', component: ProductsComponent },
  { path: 'opportunity', component: opportunityComponent },
  { path: 'Financialcalculater', component: FinancialcalculaterComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: 'privacypolicy', component: PrivacyPolicyComponent },
  { path: 'services', component: ServicesComponent }
  ,{
  path: 'coreconcepts',component: CoreConceptsComponent , children: [
    { path: 'wealthformula', component: WealthFormulaComponent },
    { path: 'Xcurve', component: XcurveComponent },
    { path: 'FinancialHouse', component: FinancialHouseComponent },
    { path: 'Didyouknow', component: payyourselffirstComponent },
    { path: 'Ruleof', component: RuleofComponent },
    { path: 'NewFinancialHouseComponent', component: NewFinancialHouseComponent },
    { path: '',redirectTo: 'wealthformula', pathMatch: 'full' },
    { path: '**', redirectTo: 'wealthformula', pathMatch: 'full'}
  ]
}
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
