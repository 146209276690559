<section class="InsuranceProducts">
    <div class="InsuranceProductsHeader FadIn">
        <h3> Insurance & Annuity Products</h3>
        <p> Browse through the variety of products that we offer and give advice on.</p>
    </div>
    <div class="container">
        <div class="row">
            <!-- <div class="col-md-6">
                <div class="Growth_img">
                    <img src="../../assets/images/micheile-henderson-lZ_4nPFKcV8-unsplash.jpg" alt="">
                </div>
            </div> -->
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-4">
                        <div class="grouth_icon rotate rotateHover" style="background-color: #6a097d;color: #fff;">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </div>
                        <div class="grouth_title">Indexed Universal Life</div>
                        <div class="grouth_Content">
                            If one needs death benefit protection and is looking to diversify the financial portfolio,
                            cash value life insurance such as fixed index universal life insurance may be an appropriate
                            addition to their strategy.
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="grouth_icon rotate rotateHover" style="background-color: #00005c;color: #fff;">
                            <i class="fa fa-money" aria-hidden="true"></i>
                        </div>
                        <div class="grouth_title">Fixed Annuities</div>
                        <div class="grouth_Content">
                            A fixed annuity is a contract between you and an insurance provider where the principal and
                            interest may be guaranteed along with a potential to withdraw lifelong income.
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="grouth_icon rotate rotateHover" style="background-color: #9a1f40;color: #fff;">
                            <i class="fa fa-line-chart" aria-hidden="true"></i>
                        </div>
                        <div class="grouth_title">Indexed Annuities</div>
                        <div class="grouth_Content">
                            Fixed Index Annuities are insurance products, not investments, that offer the opportunity
                            for some interest potential while protecting against market risk.
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="grouth_icon rotate rotateHover" style="background-color: #3797a4;color: #fff;">
                            <i class="fa fa-check" aria-hidden="true"></i>
                        </div>
                        <div class="grouth_title">Guaranteed Universal Life</div>
                        <div class="grouth_Content">
                            Guaranteed universal life insurance, referred to as a GUL, has a guaranteed death benefit
                            and as long as you pay the premiums to keep your policy active the GUL can last your entire
                            lifetime.
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="grouth_icon rotate rotateHover" style="background-color: #342b38;color: #fff;">
                            <i class="fa fa-user" aria-hidden="true"></i>
                        </div>
                        <div class="grouth_title">Term Life</div>
                        <div class="grouth_Content">
                            Protect the temporary responsibilities with temporary life insurance like Term Life.
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="grouth_icon rotate rotateHover" style="background-color: #654062;color: #fff;">
                            <i class="fa fa-handshake-o" aria-hidden="true"></i>
                        </div>
                        <div class="grouth_title">Will/ Trust Documents</div>
                        <div class="grouth_Content">
                            90% of Americans do not have a comprehensive and up-to-date Will & Estate plan. Get one NOW!
                        </div>
                    </div>

                    <div class="col-md-6" style="height: 40px;">
                       
                    </div>
                </div>
            </div>
        </div>

        <div class="row">

        </div>
    </div>
</section>
<!-- <section class="insurancepeoviderBg">
    <div class="text-center insurancepeovider_txt"> INSURANCE PROVIDERS</div>
    <div class="overlay"></div>
</section> -->