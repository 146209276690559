<div class="SubHeader FadIn">
    <h3 class="text-center ">Rule of 72 </h3>
</div>
<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">

                <div class="row">
                    <div class="col-md-6">
                        <div class="financial_img">
                            <img src="./../../assets/images/RuleOf.png" alt="">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <p class="mt-4">Everyone has some idea of what it means to be money smart - however, whether or not you've acted on that idea is a different story! There are a few nuggets of financial wisdom that have become clichés, albeit practical ones. Curb
                            your spending. Pay off your debt. Contribute to your savings early and often. Compound Interest is your friend. Start saving now and watch your money grow.</p>
                        <div class="mt-4">
                            <p>Being financially responsible starts with putting some of those clichés into action, but in doing some research into saving strategies, you might be in for an unpleasant surprise. You might do some quick calculations with current
                                interest rates and come to the sobering realization that the effects of saving your money aren't as mind-blowing as you thought. Why is that? </p>
                        </div>
                        <div class="mt-4">
                            <p>The economic landscape has changed a lot in the past 20 years. Our parents saw a time where it was possible to put your money away in a certificate of deposit (CD) with interest rates upwards of 10%. Strategically utilizing
                                investments with that kind of return was a smart move and a great way to grow your money over time.</p>
                        </div>
                    </div>
                    <div class="col-md-12 mt-5">
                        <div class="mt-4">
                            <p>Unfortunately, those days of 10% interest rates seem to have disappeared along with the era of acid-wash jeans and Troll dolls. Current interest rates are at historic lows, and the Federal Reserve predicts that the trend is
                                going to stick around for a while. Saving is, of course, still a crucial part of your financial well-being, but what's the best way to grow your money and beat inflation when interest rates are low? Consider the following
                                strategies:
                            </p>
                        </div>

                        <h3 class="text-center mb-4">Check Your Expectations.</h3>

                        <div class="mt-4">
                            <p>There’s no way to sugarcoat it; interest rates are low right now. As a result, your investments - even with the mighty power of compound interest - just aren’t going to perform as well as they would have in the past.</p>
                        </div>
                        <div class="mt-4">
                            <p>Countering the effects of inflation is another resulting challenge. But don’t get too discouraged—as a young investor, time is on your side.</p>
                        </div>
                        <div class="mt-4">
                            <p>Even low-yield investment products can generate significant wealth over long periods of time (we’re talking decades), but it’s important to stay realistic with your long-term savings goals.</p>
                        </div>
                        <div class="mt-4">
                            <p>Will your investment allow you to buy your own island when you retire? It’s highly doubtful, but with some foresight and planning, your investment can allow you to retire comfortably and with peace of mind.</p>
                        </div>
                        <div class="mt-4">
                            <p>The rich gets richer and the poor poorer. Most of the time, the missing link is “information.” The wealthy people know something that average people don’t and that is financial education. One of the secrets of the wealthy is
                                that they know how to let money work for them.</p>
                        </div>
                        <div class="mt-4">
                            <p>However, most of us don’t know how to let money work, thus we work hard for the money. But the challenging part is that nobody wakes up at 6 or 7 am and says, “yehey! It’s time to go to work!” Unless we know how to let money
                                work, we are doomed to work for the rest of our lives. So how does money work?</p>
                        </div>
                        <div class="mt-4">
                            <p>Money works through the help of interest rates. There are two types of interest rates: Simple and Compounding Interest.</p>
                        </div>
                    </div>
                </div>

                <div class="row mt-5">
                    <div class="col-md-6">
                        <h3 class="mb-4">Simple Interest:</h3>

                        <p>Example: 1% interest on the principal per year.</p>
                        <p>100 (principal savings)</p>
                        <p>After 1 year: 101</p>
                        <p>After 2 years: 102</p>
                        <p>After 100 years: 100 becomes 200! Exciting!</p>
                    </div>
                    <div class="col-md-6">
                        <div class="financial_img">
                            <img src="./../../assets/images/RuleOf1.png" alt="">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <h3 class="mb-4 mt-5">Compound Interest:</h3>

                        <p>Understanding the magic of compound interest:</p>
                        <p>Example: 1% interest compounded annually</p>
                        <p>100 (principal savings)</p>
                        <p>After 1 year: 101</p>
                        <p>After 2 years: 102.01 (because your 1 peso also earns 1% interest)</p>
                        <p>After 3 years: 103.03 (the magic of compounding!)</p>
                        <p>Experts have derived a simple formula to estimate the number of years your money doubles through compounding called the “Rule of 72.” The rule states that 72 (constant) is divided by the interest rate per year equates to the number
                            of years your money will double.</p>
                    </div>

                    <div class="col-md-12">
                        <h3 class="text-center mb-4 mt-4">72 / i = # years money doubles</h3>

                        <p>Using the values above, 72 / 1% = 72 years.</p>
                        <p>Therefore: 100 becomes 200 after 72 years (compared with 100 years in simple interest rates)</p>
                        <p>Another interesting thing to note about the magic of compounding: small changes in the interest rate = millions in the end result of your savings.</p>
                        <p>Example: 100,000 invested in 4%, 8% and 12% at age 29. Using the rule of 72, the number of years your money will double is 18, 9 and 6 years, respectively. At retirement age of 65, see the big difference below.</p>
                        <p>Obviously, the bigger the interest, the bigger the result. But the not so obvious beauty of compounding is, the difference between 4 and 8% is 2 times but the difference of 400K and 1.6M is four times! The difference of 4 and 12%
                            is thrice but the difference of 400K and 6.4M is 16 times!!! Now that is very powerful!</p>
                        <!-- <p>Try going inside the banks, you get a priority number, line up at get less than 1% per year in your regular savings account. While mr.wealthy client goes directly to the bank manager, and negotiates how much interest rate their
                            bank will give his 10M.</p> -->
                        <!-- <p>Now look closely: most Filipinos lack financial knowledge, they go to their favorite bank or cooperative, deposit their 100K at age 29, assuming they get 4% interest (in reality it’s even way lower) per year. By the time they go
                            out, the banks will invest it or lend it at 12% interest per year. When they retire at age 65, their 100K becomes 6.4M, they get 400K and the banks keep the 6M.</p>
                        <p>However, I’m not saying you withdraw all your funds now in your bank accounts. There is nothing wrong with that, it’s just that due to lack of financial literacy among Filipinos, we are not able to maximize our hard-earned money.
                            We need to understand and learn about alternative financial vehicles and investment programs that offer better rates of return. Now, who says financial education is expensive or useless? Try financial ignorance, it will cost
                            you 6M.</p> -->

                       
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>