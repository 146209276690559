import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './disclaimer.component.html',
})
export class DisclaimerComponent implements OnInit {

  ngOnInit() {
 
  }
}
