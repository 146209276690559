<!-- <div id="demo" class="carousel slide" data-ride="carousel">
  <ul class="carousel-indicators">
    <li data-target="#demo" data-slide-to="0" class="active"></li>
    <li data-target="#demo" data-slide-to="1"></li>
    <li data-target="#demo" data-slide-to="2"></li>
  </ul>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="./../../assets/images/banner1.png" alt="Los Angeles" width="1100" height="500">
      <div class="carousel-caption">

      </div>
    </div>
    <div class="carousel-item">
      <img src="./../../assets/images/banner2.png" alt="Chicago" width="1100" height="500">
      <div class="carousel-caption">

      </div>
    </div>
    <div class="carousel-item">
      <img src="./../../assets/images/banner3.png" alt="New York" width="1100" height="500">
      <div class="carousel-caption">

      </div>
    </div>
  </div>
  <a class="carousel-control-prev" href="#demo" data-slide="prev">
    <span class="carousel-control-prev-icon"></span>
  </a>
  <a class="carousel-control-next" href="#demo" data-slide="next">
    <span class="carousel-control-next-icon"></span>
  </a>
</div> -->
<div class="SubHeader FadIn">
    <h3 class="text-center ">About us</h3>
</div>
<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <p>Cadre Financial Solutions is an independent marketing company that is bringing Wall Street to Main Street. No matter what financial challenges clients may face, we have the experience and resources to create a successful financial plan.
                    Whether it is debt management, retirement strategies, life insurance or other financial needs, Cadre Financial is able to help clients achieve financial independence.</p>
                <p>Here at Cadre Financial Solutions, we seek to offer you the highest level of assistance. Your financial priorities will be at the forefront of our attention. We want to assist you in reducing financial burdens and building wealth. Our
                    guiding goal is to ensure that you accomplish your financial goals for the present and the future.</p>
                <p>Through our portfolio of businesses, we offer services and solutions to help our clients to realize their ambitions.
                </p>
                <p>We provide solutions to our clients and help manage and grow their wealth throughout their lives. We seek to provide whole-of-wealth services to our clients, taking a holistic view of a client’s needs at every stage of their life, and
                    providing best of breed financial solutions.</p>
                <p>Our solutions seek to address the ‘big six’ financial requirements affecting most Americans – managing cash flows, managing debts, managing emergency funds, growing assets, enjoying retirement, and protecting their family’s future.</p>
                <p>Contact us now and let us guide you to build and secure your finances.</p>
            </div>

            <div class="col-md-12 mt-4">
                <div class="list_title">With that strong belief and as per your needs and goals, we will show you strategies to – </div>
                <ul class="list_">
                    <li>Create and secure wealth for your present and your future.</li>
                    <li>Create and secure wealth for your child's future.</li>
                    <li>Create tax-free retirement earnings.</li>
                    <li>Create extra earnings to use for vacations and fulfill your dreams.</li>
                    <li>Protect your investments from the recession and market risk.</li>
                    <li>Secure funds for your child's education so your child will not be burdened with student loans after finishing college.</li>
                    <li>Protect your retirement money from market crash and recession.</li>
                    <li>Receive tremendous income every year even after you retire and you never have to worry about receiving social security benefits which you know will never be enough.</li>
                </ul>

            </div>
        </div>
    </div>
</section>