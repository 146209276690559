import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

import { environment } from './../../environments/environment';

@Component({
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  model: any = {
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  };

  constructor(private http: HttpClient) {

  }

  ngOnInit() {

  }
  errorAlert:boolean=false;
  showpopup:boolean=false;
  sendemail() {
    if (!this.model.name) {
    this.errorAlert=true;
      return;
    }
    if (!this.model.email) {
      this.errorAlert=true;
      return;
    }
    if (!this.model.phone) {
      this.errorAlert=true;
      return;
    }
    if (!this.model.subject) {
      this.errorAlert=true;
      return;
    }
    if (!this.model.message) {
      this.errorAlert=true;
      return;
    }

    const headers = {
    //  'Authorization': 'Bearer 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',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
    'Access-Control-Allow-Headers':'X-Requested-With'   
    };
    const body = this.model;
    this.http.post<any>(environment.apiurl+'Admin/SubmitContactUsEmail', body, { headers }).subscribe(data => {
      this.model = {
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: ''
      };  
      this.showpopup=true;
    // alert("Thank you for the enquiry. We will contact you with in next 24 hours.");

    });

  }
}
