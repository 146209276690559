<div class="SubHeader FadIn">
    <h3 class="text-center ">Disclaimer</h3>
</div>
<section>
    <div class="container-fluid">
        <div class="row" style="margin: 0px 40px 0px 40px;">
            <div class="col-md-4">
                <img src="./../../assets//images/disclaimer.jpg" style="height:100%;width: 100%;">
            </div>
            <div class="col-md-8">
                <p style="font-size: 16px;font-family: serif;text-align: left;">All written content on this site is for information purposes only. Opinions expressed herein are
                    solely
                    those of Cadre Financial Solutions and our staff. Material presented is believed to be from reliable
                    sources; however, we make no representations as to its accuracy or completeness. Cadre Financial
                    Solutions is a marketing company offering a vast array of products and services from various
                    providers
                    through a network of independent affiliates. Cadre Financial Solutions does not provide any
                    insurance,
                    investment, retirement, or financial products of their own. Guarantees (if any) are backed by the
                    financial strength and claims-paying ability of the issuing company. All information and ideas
                    should be
                    discussed in detail with your individual financial professional prior to implementation. Insurance
                    products and services that are offered through Cadre Financial Solutions are not affiliated with or
                    endorsed by the Social Security Administration or any other government agency. This content is for
                    informational purposes only and should not be used to make any financial decisions. Exclusive rights
                    to
                    this material belong to Cadre Financial Solutions. Unauthorized use of the material is prohibited.
                    Cadre
                    Financial Solutions disclaims any and all liability in the event any information, commentary,
                    analysis,
                    opinions, advice and/or recommendations prove to be inaccurate, incomplete or unreliable or result
                    in
                    any investment or other losses. Products & Services may change anytime.
                </p>
            </div>

        </div>
    </div>
</section>