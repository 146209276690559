<div class="SubHeader FadIn">
    <h3 class="text-center ">Pay yourself first </h3>
</div>
<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-12">
                        <div class="financial_img">
                            <img src="./../../assets/images/PayFirst.png" alt="">
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="mt-4">Pay Yourself First means Invest or save first before making any expense. It is
                            similar to save first and then spend. This means saving and investment should on your top
                            priority list.</div>
                        <div class="mt-4">
                            <p>I know it is difficult to follow this rule due to psychological limitation. We are
                                programmed to handle money as spender. We can easily plan to spend money. However, it is
                                difficult for us to save money.</p>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="mt-4">
                            <p>The similar concept can be explained by an analogy of doing exercise. Many experts
                                recommend “Doing Exercise First in the morning”. Regular exercise in the morning helps
                                you to stay healthy. However, due to psychological reasons,
                                many people say that they don’t have time to exercise in the morning. Later in the day
                                they may or may not do exercise.</p>
                        </div>
                        <div class="mt-4">
                            <p>Pay Yourself First is a similar concept. If you pay money to everyone else you may or may
                                not save money for yourself. However, if you follow this concept very well you can be
                                rich for sure.</p>
                        </div>

                        <a>How to Become Rich by Pay Yourself First?</a>
                    </div>

                    <div class="col-md-12 mt-4">
                        <div class="list_title">Follow the step given below to become rich via – “Pay Yourself First”.
                        </div>
                        <ul class="list_">
                            <li>First thing is to find out your monthly outgo on mandatory expenses. Once you have this
                                detail find out how much money you can save from your income? It is advisable to save
                                and invest at least 10% of your monthly income to
                                start with.</li>
                            <li>Once you decide the amount you need to automate your investment in the assets that
                                provide sufficient growth or generate passive income. It could be mutual funds, stocks,
                                PPF, real estate, IUL etc. Payment should go to investment
                                as soon as you receive your paycheck.</li>
                            <li>By using this method you can establish a flow of steady passive income over the period.
                                It could be either dividend, interest income or maybe rent. This passive income should
                                reach a level where you can manage all your expenses
                                from passive income. This is called as Financial Independence stage.</li>
                            <li>Once you reach this stage. You can invest your entire salary/income to buy an asset that
                                generates additional passive income.</li>
                        </ul>

                    </div>
                </div>
            </div>

            <div class="col-md-12">
                <h3 class="text-center mb-4 mt-4">Savings</h3>
                <p>Saving money is a habit and so is spending. While most of us are already “masters” in spending, we
                    need to understand that saving bears equal weight with spending and our saving habits can determine
                    whether we are ready to accumulate
                    wealth. When one receives income from a job or salary, a lot of people are using the wrong savings
                    formula. How about you, what is your savings formula?</p>

                <h3 class="text-center mb-4 mt-4">Here are 3 savings equations and see which one is yours.</h3>
                <div class="sub_title mb-3">
                    (1.) Income – Expenses = Savings
                </div>
                <p>After receiving income, majority of your funds are then budgeted and spent on your expenses including
                    but not limited to daily expenses, food, clothing, transportation, bills: water, electricity, phone
                    bills, mobile loads, schooling,
                    vitamins and/or medicines, lifestyle, travel, gadgets, and the list may go on and on. And after all
                    that, whatever is left is for savings.</p>
                <p><b> Question:</b> does this formula work? Most the time, there is nothing left to save!</p>

                <div class="sub_title mb-3">
                    (2.) Expenses – Income = Savings
                </div>
                <p>This formula is what I believe the worst formula in saving money. Before receiving income, you
                    already spent money through credit cards or loans. This is a very dangerous formula because there
                    may come a time that after paying
                    all debts, nothing is left not just for saving money but also for your daily expenses and may
                    require you to go into more debts.</p>

                <div class="sub_title mb-3">(3.) Income – Savings = Expenses</div>
                <p>This is the better formula. Again, saving is a habit. Unless we automatically set aside money for
                    saving, our spending habits will always prevail. Saving is a decision, and regardless how much
                    amount you set aside, the act or “discipline”
                    of saving money will create the habit that will eventually lead us ready in creating wealth.</p>

                <!-- <div class="sub_title mb-3">(4.) Income – Tithes – Savings = Expenses</div>
                <p>This is the best formula. 100% Income less 10% Tithing, less 5-20% Savings equals 70% Expenses.
                    According to the Bible, our tithe is our love percent, the percentage we give back to God for the
                    blessings we received. This formula
                    just works in miraculous ways: the more you give, the more you receive.</p>

                <p><b>Objection:</b> This is a formula that is very difficult to follow. Income is not even enough for
                    all the expenses.</p>
                <p>Truth: Income will never be enough because there will always be reasons to spend. Saving is again a
                    decision, a discipline. Everything hurts the first time, just continue doing the habit, then you’ll
                    be amazed how powerful this
                    savings formula is.</p>

                <p><b>Question:</b> Why only give 10% to God and give 20% for your savings?</p>
                <p><b>Answer:</b>So that when the 20% savings and investments reach maturity, by the time we retire, we
                    can still continue to give even when we no longer have income from our job or business.</p>
                <p>According to Brother Bo Sanchez, give 10% for eternity, 20% for maturity and 70% for the family. And
                    that for me is the best savings formula.</p>-->
            </div> 

        </div>
    </div>
</section>