<div class="SubHeader FadIn">
    <h3 class="text-center ">Services</h3>
</div>
<section>
    <div class="container-fluid">
        <div class="row" style="margin: 0px 50px 0px 50px;">
            <div class="col-md-4">
                <div class="row customcard">
                
                    <div class="col-md-3">
                        <div class="grouth_icon rotate rotateHover" style="background-color: #F44336;color: #fff;">
                            <i class="fa fa-home" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="grouth_titleservice">Estate Planning</div>
                        <div class="grouth_Content">
                            Protect your valuable financial assets against the litigation and unfavorable taxation.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row customcard">
                    <div class="col-md-3">
                        <div class="grouth_icon rotate rotateHover" style="background-color: #3F51B5;;color: #fff;">
                            <i class="fa fa-child" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="grouth_titleservice">Retirement Planning</div>
                        <div class="grouth_Content">
                            Take care of your money now so you won’t have to later.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row customcard">
                    <div class="col-md-3">
                        <div class="grouth_icon rotate rotateHover" style="background-color: #ff9800;color: #fff;">
                            <i class="fa fa-heart" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="grouth_titleservice">Lifetime Income Planning</div>
                        <div class="grouth_Content">
                            When people do not have pension-like income, guaranteed lifetime income planning is imperative.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row customcard">
                    <div class="col-md-3">
                        <div class="grouth_icon rotate rotateHover" style="background-color: #009688;color: #fff;">
                            <i class="fa fa-compress" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="grouth_titleservice">IRA / 401K Rollover</div>
                        <div class="grouth_Content">
                            Keeping the tax deferred account in check is important. Know your rollover options well before you take an action.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row customcard">
                    <div class="col-md-3">
                        <div class="grouth_icon rotate rotateHover" style="background-color: #607d8b;color: #fff;">
                            <i class="fa fa-user-secret" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="grouth_titleservice">Annuities</div>
                        <div class="grouth_Content">
                            Another way to earn lifetime income after Pension and Social Security income.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row customcard">
                    <div class="col-md-3">
                        <div class="grouth_icon rotate rotateHover" style="background-color: #79ce17;color: #fff;">
                            <i class="fa fa-line-chart" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="grouth_titleservice">Life Insurance</div>
                        <div class="grouth_Content">
                            Transfer the financial responsibility from your shoulders to the life insurance company.
                        </div>
                    </div>
                </div>
            </div>
       
            <div class="col-md-4">
                <div class="row customcard">
                    <div class="col-md-3">
                        <div class="grouth_icon rotate rotateHover" style="background-color: #117a8b;color: #fff;">
                            <i class="fa fa-lock" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="grouth_titleservice">Mortgage Protection

                        </div>
                        <div class="grouth_Content">
                            Provision your mortgage to be paid off in case the mortgage holder passes away.
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row customcard">
                    <div class="col-md-3">
                        <div class="grouth_icon rotate rotateHover" style="background-color: #809400;color: #fff;">
                            <i class="fa fa-money" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="grouth_titleservice">Investments
                        </div>
                        <div class="grouth_Content">
                            Grow your money and know the difference between rate of return and real rate of return.

                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row customcard">
                    <div class="col-md-3">
                        <div class="grouth_icon rotate rotateHover" style="background-color: #ff9966;color: #fff;">
                            <i class="fa fa-check-circle" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="col-md-9">
                        <div class="grouth_titleservice">Will and Trust

                        </div>
                        <div class="grouth_Content">
                            Essential estate planning tools.

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>