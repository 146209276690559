import { Component, OnInit } from '@angular/core';

@Component({
   selector: 'cadre-us-app',
  templateUrl: 'payyourselffirst.component.html',
  styleUrls: ['payyourselffirst.component.css']
})
export class payyourselffirstComponent implements OnInit {

  ngOnInit() {
 
  }
}
