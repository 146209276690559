<div class="SubHeader FadIn">
    <h3 class="text-center ">Privacy Policy</h3>
</div>

<div class="container">
    <div class="row">
        <div class="col-md-12">
            <p>Cadre Financial Solutions and it's associates are committed to ensuring your financial privacy.

                Our relationship with you is very important to us. We understand that you have entrusted us with your
                private financial information and we do everything we can to maintain that trust. We collect personal
                information from you to open your accounts(s) and to provide financial planning advice. We do not sell
                your
                personal information to anyone. It is our policy to withhold customer information from companies outside
                of
                Cadre Financial Solutions unless required for the operational support of delivering investment services.
                These companies are prohibited from using client information for anything beyond the intended services
                and
                will be denied access to client information if confidentiality cannot be ensured.
            </p>



            <p>Only such information received from you, through application forms, financial planning documents, or
                otherwise, will be collected.
                None of such information about you (or former clients) will be disclosed to anyone, except as permitted
                by
                law (which includes disclosure to employees necessary to service your account).
                Policies and procedures (including physical, electronic and procedural safeguards) are in place that are
                designed to protect the confidentiality of such information.
                For additional information about our privacy policy or if you have any questions, please call our office
                at
                862-571-8191
            </p>
        </div>

        <div class="col-md-12">
            <h3>WHY?</h3>
            <p>
                Financial companies choose how they share your personal information. Federal law gives consumers the
                right
                to limit some but not all sharing. Federal law also requires us to tell you how we collect, share, and
                protect your personal information. Please read this notice carefully to understand what we do.

            </p>
        </div>


        <div class="col-md-12">
            <h3>WHAT?</h3>
        </div>
        <div class="col-md-12">
            <p>The types of personal information we collect and share depend on the product or service you have / want
                with us.
            </p>


            <b>This can include:</b>
            <div>
                <ul>
                    <li>Social Security Number</li>
                    <li>Date of Birth</li>
                    <li>Income</li>
                    <li>Investment experience</li>
                    <li>Risk tolerance</li>
                    <li>Retirement assets/li>
                    <li>Wire transfer instructions</li>
                    <li>When you are no longer our customer, we do not continue to share your information as described
                        in this notice.</li>
                </ul>
            </div>

        </div>
        <div class="col-md-12">
            <h3> HOW?</h3>

            <p>All financial companies need to share customers' personal information to run their everyday business. In
                the
                section below, we list the reasons financial companies can share their customers' personal information;
                the
                reasons Cadre Financial Solutions chooses to share; and whether you can limit this sharing.
            </p>


            <h3>Reasons we can share your personal information:</h3>

            <p><b> For our everyday business purposes-</b> such as to process your transactions, maintain your
                account(s),
                respond
                to court orders and legal investigations. Cadre Financial Solutions will share your personal
                information.
            </p>


            <p>
                <b> For marketing purpose -</b> to offer our products and services to you. Cadre Financial Solutions
                will not
                share
                your personal information.
            </p>
            <p>
                <b>For joint marketing with other financial companies – </b>Cadre Financial Solutions will not share
                your
                personal
                information.
            </p>
            <p>
                <b>For our affiliates everyday business purposes-</b> information about your creditworthiness. Cadre
                Financial
                Solutions will not share your personal information.
            </p>
            <p>
                <b>For non affiliates to market to you -</b> Cadre Financial Solutions will not share your personal
                information.
            </p>
        </div>
        <div class="col-md-12">
            <h3>How does Cadre Financial Solutions protect your information:</h3>

            <p>protect your personal information from unauthorized access and use, we use security measures that comply
                with federal law. These measures include computer safeguards and secured files and buildings.
            </p>
            <h3>How does Cadre Financial Solutions collect personal information:</h3>

            <b> We collect your personal information, for example</b>
            <ul>
                <li>Enter into an investment advisory agreement</li>
                <li> When you open an account</li>
                <li>Seek financial or tax advice</li>
                <li>Seek advice about your investments</li>
                <li>Tell us about your investment or retirement portfolio</li>
                <li>Make deposits or withdrawals from your account</li>
                <li>Why can’t I limit all sharing?</li>
            </ul>

        </div>

        <div class="col-md-12">
        <b>Federal law gives you the right to limit only</b>

        <ul>
            <li>Sharing for affiliates' everyday business — information about your creditworthiness.</li>
            <li>Affiliates from using your information to market to you.</li>
            <li>Sharing for non-affiliates to market to you.</li>
        </ul>
        State laws and individual companies may give you additional rights to limit sharing.
        <br>
        <b>Definitions:</b>
        <p> Some state regulations require an affirmative opt-in before information can be shared with
            non-affiliated
            third parties; an opt-out for sharing amongst affiliates; and a self- addressed envelope with pre-paid
            postage if there is not at least two free ways to respond (e.g. toll free telephone or website).
        </p>

        <b>Affiliates</b>
        <p> Companies related by common ownership or control. They can be financial and nonfinancial companies.
        </p>

        <b>Non-affiliates</b>
        <p>Companies not related by common ownership or control. They can be financial and nonfinancial companies.
        </p>

        <b>Joint marketing</b>

        <p> A formal agreement for non affiliates to market to you non affiliated financial companies that together
            market financial products or services to you.
        </p>
        <b> Questions: Call 862-571-8191</b>
    </div>
    </div>

    </div>