<!-- <nav class="navbar navbar-expand-lg navbar-dark bg-dark" style="margin:24px 0;">
    <a class="navbar-brand" href="javascript:void(0)">Logo</a>
    <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navb" aria-expanded="true">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="navbar-collapse collapse show" id="navb" style="">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)">Link</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="javascript:void(0)">Link</a>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled" href="javascript:void(0)">Disabled</a>
        </li>
      </ul>
      <form class="form-inline my-2 my-lg-0">
        <input class="form-control mr-sm-2" type="text" placeholder="Search">
        <button class="btn btn-success my-2 my-sm-0" type="button">Search</button>
      </form>
    </div>
  </nav> -->




<nav class="navbar navbar-expand-sm nav_menu navbar-dark sticky-top " style="height: 90px;">
    <div class="logo">
        <!-- <h6>Carde </h6>
        <p>Financial Solutions</p> -->
        <a href="#"> <img class="logoimg" src="./../assets//images/logo.png"></a>
    </div>
    <button class="navbar-toggler navbar-toggler-right" (click)="menuclick()" type="button" data-toggle="collapse" #navbarToggler data-target="#navb" aria-expanded="true">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse collapse" id="navb">
        <div class="flexGrow"></div>
        <ul class="navbar-nav nav_menu_list">
            <li>
                <a class="nav-link HeaderMenu_color" routerLink="" (click)="collapseNav()">Home</a>
            </li>
            <li>
                <a class="nav-link HeaderMenu_color" routerLink="about" (click)="collapseNav()" routerLinkActive="nav-item activemenu">About Us
            </a>
            </li>

            <li class=" droupdown_body web_responsive">
                <a class="nav-link HeaderMenu_color" routerLink="coreconcepts" routerLinkActive="nav-item activemenu">Core Concepts</a>
                <div class="droupdown_content">
                    <ul>
                        <li> <a routerLink="coreconcepts/wealthformula" routerLinkActive="activemenu" (click)="collapseNav()"> Wealth Formula </a></li>
                        <li> <a routerLink="coreconcepts/FinancialHouse" routerLinkActive="activemenu" (click)="collapseNav()"> Financial House </a></li>
                        <li> <a routerLink="coreconcepts/Didyouknow" routerLinkActive="activemenu" (click)="collapseNav()"> Pay Yourself First</a></li>
                        <li><a routerLink="coreconcepts/Ruleof" routerLinkActive="activemenu" (click)="collapseNav()"> Rule of 72</a></li>
                        <li><a routerLink="coreconcepts/NewFinancialHouseComponent" (click)="collapseNav()" routerLinkActive="activemenu"> Financial Life
                            Cycle</a></li>
                        <li routerLink="coreconcepts/Xcurve" (click)="collapseNav()" routerLinkActive="activemenu"><a> X - Curve </a></li>
                    </ul>
                </div>
            </li>

            <li class=" droupdown_body mob_responsive">
                <div style="position: relative;">
                    <!--routerLink="coreconcepts" routerLinkActive="nav-item activemenu"  -->
                    <a class="nav-link HeaderMenu_color" routerLink="coreconcepts" routerLinkActive="nav-item activemenu">Core Concepts </a>
                    <!-- <i class="fa fa-chevron-right droup_attachmemt activemenuicon" (click)="ToggleDroupdown()" aria-hidden="true"></i> -->
                </div>
                <!-- <div class="droupdown_content" *ngIf="isShowToggleDroupdown">
                    <ul>
                        <li> <a routerLink="coreconcepts/wealthformula" routerLinkActive="activemenu" (click)="collapseNav()"> Wealth Formula </a></li>
                        <li> <a routerLink="coreconcepts/FinancialHouse" routerLinkActive="activemenu" (click)="collapseNav()"> Financial House </a></li>
                        <li> <a routerLink="coreconcepts/Didyouknow" routerLinkActive="activemenu" (click)="collapseNav()"> Pay Yourself First</a></li>
                        <li><a routerLink="coreconcepts/Ruleof" routerLinkActive="activemenu" (click)="collapseNav()"> Rule of 72</a></li>
                        <li><a routerLink="coreconcepts/NewFinancialHouseComponent" (click)="collapseNav()" routerLinkActive="activemenu"> Financial Life
                            Cycle</a></li>
                        <li routerLink="coreconcepts/Xcurve" (click)="collapseNav()" routerLinkActive="activemenu"><a> X - Curve </a></li>
                    </ul>
                </div> -->
            </li>
            <li class="nav-item mob_submenus"><a class="nav-link HeaderMenu_color" routerLink="coreconcepts/wealthformula" routerLinkActive="activemenu" (click)="collapseNav()"> Wealth Formula </a></li>
            <li class="nav-item mob_submenus"><a class="nav-link HeaderMenu_color" routerLink="coreconcepts/FinancialHouse" routerLinkActive="activemenu" (click)="collapseNav()"> Financial House </a></li>
            <li class="nav-item mob_submenus"><a class="nav-link HeaderMenu_color" routerLink="coreconcepts/Didyouknow" routerLinkActive="activemenu" (click)="collapseNav()"> Pay Yourself First</a></li>
            <li class="nav-item mob_submenus"><a class="nav-link HeaderMenu_color" routerLink="coreconcepts/Ruleof" routerLinkActive="activemenu" (click)="collapseNav()"> Rule of 72</a></li>
            <li class="nav-item mob_submenus"><a class="nav-link HeaderMenu_color" routerLink="coreconcepts/NewFinancialHouseComponent" (click)="collapseNav()" routerLinkActive="activemenu"> Financial Life Cycle</a></li>
            <li class="nav-item mob_submenus"><a class="nav-link HeaderMenu_color" routerLink="coreconcepts/Xcurve" (click)="collapseNav()" routerLinkActive="activemenu"> X - Curve </a></li>

            <li class="nav-item">
                <a class="nav-link HeaderMenu_color" routerLink="Financialcalculater" (click)="collapseNav()" routerLinkActive="activemenu">Financial Calculators</a>
            </li>
            <li class="nav-item">
                <a class="nav-link HeaderMenu_color" routerLink="product" (click)="collapseNav()" routerLinkActive="activemenu"> Products</a>
            </li>
            <li class="nav-item">
                <a class="nav-link HeaderMenu_color" routerLink="services" (click)="collapseNav()" routerLinkActive="activemenu"> Services</a>
            </li>
            <li class="nav-item">
                <a class="nav-link HeaderMenu_color" routerLink="opportunity" (click)="collapseNav()" routerLinkActive="activemenu">Opportunity</a>
            </li>
            <li class="nav-item">
                <a class="nav-link HeaderMenu_color" routerLink="contact" (click)="collapseNav()" routerLinkActive="activemenu">Contact </a>
            </li>


        </ul>
    </div>
</nav>

<div class="notification" routerLink="contact">
    <i class="fa fa-commenting" aria-hidden="true" style="font-size: 25px;"></i>
    <div class="notification_content"> SCHEDULE A FREE CONSULTAION WITH A FINANCIAL ADVISOR </div>
</div>

<!-- Content Area -->
<div>
    <router-outlet></router-outlet>
</div>

<section class="ContactUS_footer">
    <div class="row">
        <div class="col-md-12">
            <p class="cpy">Copyright © 2020. Cadre Financial Solutions. All rights reserved</p>
            <a class="policy" target="blank" routerLink="disclaimer">Disclaimer</a> |
            <a class="policy" target="blank" routerLink="privacypolicy">Privacy Policy</a>
        </div>
        <!-- <div class="col-md-6">
            <i class="fa fa-facebook socialmedia rotate rotateHover" aria-hidden="true"></i>
            <i class="fa fa-linkedin socialmedia rotate rotateHover" aria-hidden="true"></i>
        </div> -->
    </div>
</section>