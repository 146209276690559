import { Component } from '@angular/core';
import {  ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'cadre-us-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'CadreUS';
  @ViewChild('navbarToggler') navbarToggler:ElementRef;

  menushow = true;
  isShowToggleDroupdown = false;

  menuclick() {
    this.menushow = !this.menushow;
  }
  ToggleDroupdown(){
    this.isShowToggleDroupdown = !this.isShowToggleDroupdown;
  }
  navBarTogglerIsVisible() {
    return this.navbarToggler.nativeElement.offsetParent !== null;
  }


  collapseNav() {
    if (this.navBarTogglerIsVisible()) {
      this.navbarToggler.nativeElement.click();
    }
  }
}
