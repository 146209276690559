import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HomeComponent } from './home/home.component'
import { AboutComponent } from './about/about.component'
import { ContactComponent } from './Contact/contact.component'
import { FinancialHouseComponent } from './FinancialHouse/financialhouse.component'
import { ProductsComponent } from './Products/products.component'
import { WealthFormulaComponent } from './WealthFormula/wealthformula.component'
import { opportunityComponent } from './Opportunity/opportunity.component'
import { payyourselffirstComponent } from './Didyouknow/payyourselffirst.component'
import { RuleofComponent } from './RuleOf/ruleof.component'
import { FinancialcalculaterComponent } from './FinancialCalculators/Financialcalculater.component';
import { NewFinancialHouseComponent } from './new-financial-house/new-financial-house.component';
import { XcurveComponent } from './xcurve/xcurve.component';
import { ServicesComponent } from './services/services.component';
import { PrivacyPolicyComponent } from './privacypolicy/privacypolicy.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { CoreConceptsComponent } from './CoreConcepts/coreconcepts.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    ContactComponent,
    FinancialHouseComponent,
    ProductsComponent,
    WealthFormulaComponent,
    opportunityComponent,
    payyourselffirstComponent,
    RuleofComponent,
    FinancialcalculaterComponent,
    NewFinancialHouseComponent,
    XcurveComponent,
    ServicesComponent,
    PrivacyPolicyComponent,
    DisclaimerComponent,
    CoreConceptsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule

  ],
  providers: [{provide : LocationStrategy , useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
