<section class="Contact_US FadIn">
    <div class="contactUS_header">Contact Us</div>

    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="contact_subTitle">Phone Number:</div>
                <p class="contact_Details">+1 862-571-8191 </p>
            </div>
            <!-- <div class="col-md-4">
                <div class="contact_subTitle">Address:</div>
                <p class="contact_Details">3675 Crestwood Parkway, </p>
                <p class="contact_Details">Suite # 506 Duluth, </p>
                <p class="contact_Details">GA 30096</p>
            </div> -->
            <div class="col-md-6">
                <div class="contact_subTitle">Email:</div>
                <p class="contact_Details">contact@CadreUS.com</p>
            </div>
        </div>
    </div>
</section>
<section class="Contact_US2">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="contactUS-Title2">
                    Or, Ask Us Your Queries Below and We Will Get Back to You
                </div>
            </div>
            <div class="col-md-6">
                <div class="contact_img">
                    <img src="../../assets/images/imgpsh_fullsize_anim.jpg" alt="">
                </div>
            </div>
            <div class="col-md-6 mt-5">
                <div class="form-group">
                    <label for="Name">Name:</label>
                    <input type="text" class="form-control" id="Name" [(ngModel)]="model.name">
                </div>

                <div class="form-group">
                    <label for="Email">Email:</label>
                    <input type="text" class="form-control" id="Email" [(ngModel)]="model.email">
                </div>

                <div class="form-group">
                    <label for="Phone">Phone:</label>
                    <input type="text" class="form-control" id="Phone" [(ngModel)]="model.phone">
                </div>

                <div class="form-group">
                    <label for="Subject">Subject:</label>
                    <input type="text" class="form-control" id="Subject" [(ngModel)]="model.subject">
                </div>

                <div class="form-group">
                    <label for="message">Message:</label>
                    <textarea class="form-control" id="message" rows="3" [(ngModel)]="model.message"></textarea>
                </div>

                <div class="col-md-6" style="margin: auto;">
                    <button class="contact_btn" (click)="sendemail()">Sent Message</button>
                </div>

            </div>
        </div>
    </div>
</section>


<div class="popup_body" *ngIf="showpopup">
    <div class="popup_content">
        <!-- <i class="fa fa-times popupclose" (click)="showpopup=false" aria-hidden="true"></i> -->
        <!-- <div class="list_title">Email sent </div> -->
        <div class="list_">
            <div class="thank-you-pop">
                <img src="http://goactionstations.co.uk/wp-content/uploads/2017/03/Green-Round-Tick.png" alt="">
                <h1>Thank You!</h1>
                <p>Thank you for the enquiry. We will contact you with in next 24 hours.</p>
                <!-- <h3 class="cupon-pop">Your Id: <span>12345</span></h3> -->
                <a (click)="showpopup=false">Close</a>
            </div>
        </div>

    </div>
</div>

<div class="popup_body" *ngIf="errorAlert">
    <div class="popup_content">
        <!-- <i class="fa fa-times popupclose" (click)="showpopup=false" aria-hidden="true"></i> -->
        <!-- <div class="list_title">Email sent </div> -->
        <div class="list_">
            <div class="thank-you-pop">
                <p>All fields are required. Please fill all the fields. </p>
                <!-- <h3 class="cupon-pop">Your Id: <span>12345</span></h3> -->
                <a (click)="errorAlert=false" style="cursor: pointer;">Close</a>
            </div>
        </div>

    </div>
</div>


<!-- <section>
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <div class="contact_img">
                    <img src="./../../assets/images/profile.png" alt="Contact Profile Image">
                </div>
            </div>
            <div class="col-md-9">
                <h3 class="text-left mt-4">CONTACT SANDIP</h3>
                <p>Call/Text Sandip: <a class="link"> 862.571.8191</a></p>

                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="First Name" name="FirstName">
                          </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Last Name" name="LastName">
                          </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Cell" name="Cell">
                          </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Enter Email" name="Email">
                          </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group">
                            <textarea class="form-control" placeholder="Message" rows="5" name="Message"></textarea>
                          </div>
                    </div>
                    <div class="col-md-12">
                        <button class="theme_btn float-right"> Sent </button>
                    </div>
                </div>

            </div>
        </div>
      
    </div>
</section> -->