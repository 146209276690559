import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './coreconcepts.component.html',
  styleUrls: ['./coreconcepts.component.css']
})
export class CoreConceptsComponent implements OnInit {

  ngOnInit() {
 
  }
}
