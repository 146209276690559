import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './privacypolicy.component.html',
})
export class PrivacyPolicyComponent implements OnInit {

  ngOnInit() {

  }
}
