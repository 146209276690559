<div class="SubHeader FadIn">
    <h3 class="text-center">Financial Calculators</h3>
</div>
<section style="padding: 0;">
    <div class="container">
        <div class="row">
            <div class="col-md-6">


                <div class="mb-2">
                    <i class="fa fa-circle bullet" aria-hidden="true"></i> <a href="http://www-146.aig.com/calcs/CompoundInterest.html" target="blank" class="link">Rule of 72 / Compounding and Your Return Calculator</a>
                </div>
                <div class="mb-2">
                    <i class="fa fa-circle bullet" aria-hidden="true"></i> <a href="http://www-146.aig.com/calcs/InvestmentDistribution.html" target="blank" class="link">Investment Savings & Distributions Calculator</a>
                </div>
                <div class="mb-2">
                    <i class="fa fa-circle bullet" aria-hidden="true"></i> <a href="http://www-146.aig.com/calcs/ImmediateAnnuity.html" target="blank" class="link">Immediate Annuity Calculator</a>
                </div>
                <div class="mb-2">
                    <i class="fa fa-circle bullet" aria-hidden="true"></i> <a href="http://www-146.aig.com/calcs/InvestCompare.html" target="blank" class="link">Taxable vs. Tax Deferred vs. Tax Free Investment Calculator</a>
                </div>
                <div class="mb-2">
                    <i class="fa fa-circle bullet" aria-hidden="true"></i> <a href="http://www-146.aig.com/calcs/LifeTime.html" target="blank" class="link">Life Expectancy Calculator</a>
                </div>
                <div class="mb-2">
                    <i class="fa fa-circle bullet" aria-hidden="true"></i> <a href="http://www-146.aig.com/calcs/WaitCost.html" target="blank" class="link">The High Cost of Waiting / Don't Delay Your Savings Calculator </a>
                </div>
                <div class="mb-2">
                    <i class="fa fa-circle bullet" aria-hidden="true"></i> <a href="http://www-146.aig.com/calcs/LongtermCare.html" target="blank" class="link">Long Term Care Calculator </a>
                </div>
                <div class="mb-2">
                    <i class="fa fa-circle bullet" aria-hidden="true"></i> <a href="http://www-146.aig.com/calcs/RetireDistrib.html" target="blank" class="link">Required Minimum Distribution (RMD) Calculator</a>
                </div>
                <div class="mb-2">
                    <i class="fa fa-circle bullet" aria-hidden="true"></i> <a href="http://www-146.aig.com/calcs/RetirementIncome.html" target="blank" class="link">Retirement Income Calculator </a>
                </div>
                <div class="mb-2">
                    <i class="fa fa-circle bullet" aria-hidden="true"></i> <a href="http://www-146.aig.com/calcs/RetirementPlan.html" target="blank" class="link">Retirement Planner Calculator</a>
                </div>
                <div class="mb-2">
                    <i class="fa fa-circle bullet" aria-hidden="true"></i> <a href="http://www-146.aig.com/calcs/RetireShort.html" target="blank" class="link">Retirement Shortfall Calculator</a>
                </div>
                <div class="mb-2">
                    <i class="fa fa-circle bullet" aria-hidden="true"></i> <a href="http://www-146.aig.com/calcs/RothTransfer.html" target="blank" class="link">Roth IRA Conversion Calculator </a>
                </div>
                <div class="mb-2">
                    <i class="fa fa-circle bullet" aria-hidden="true"></i> <a href="http://www-146.aig.com/calcs/SocialSecurity.html" target="blank" class="link">Social Security Benefit Calculator</a>
                </div>
            </div>
            <div class="col-md-6">
                <div class="calculator_img">
                    <img src="../../assets/images/Calculator.png" alt="">
                </div>
            </div>
        </div>
    </div>
</section>