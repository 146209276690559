<div class="SubHeader FadIn">
    <h3 class="text-center">X - Curve </h3>
</div>
<section style="padding: 0;">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-5">
                        <img src="./../../assets//images/xcurve.jpg">
                    </div>
                    <div class="col-md-7">
                        <p>In wealth building one must understand the importance of the relationship between our
                            responsibilities and the amount of wealth we have created. This concept is called the
                            X-curve
                            concept. What is the X-curve concept?</p>
                        <p>The X-curve is a graph showing two major lines in our lives: the wealth or savings line and
                            our
                            responsibility line. It is shown that ideally from younger to older years, our wealth and
                            savings line should go up while our responsibility
                            line simultaneously should go down.</p>
                        <p class="mb-5">The X-curve is divided into 4 quadrants. Quadrants I and II represents our
                            younger
                            years while III and IV represents our older years.</p>
                    </div>
                </div>

                <p><b>1. QI:</b> shows that when we are young, we have very high responsibility. One of our major
                    responsibilities is our income from our job or business. The challenge is that our income is usually
                    temporary <em> (nobody works forever) </em> and our needs like food, shelter, clothing, education,
                    etc. are permanent.</p>
                <p>Thus, temporary income supporting permanent needs. It’s going to be very difficult to say:</p>
                <p class="mb-5"><em>“I’m now retired! I have no more income. I will also retire eating and taking a
                        bath! What a
                        comfortable life!” (sarcasm intended)</em></p>

                <p><b>2. QII:</b> in quadrant II, typically when we are young, we have little to no savings at all.
                    That’s why we work hard for money to provide for our family and ourselves.</p>
                <p>During our younger years, we are very less secure. The question we need to consider is:</p>
                <p class="mb-5"><em>what if we die too soon? Are we prepared? Is our family ready? Who will take care of
                        them?</em>
                </p>

                <p class="mb-5"><b>3. QIII:</b>due to the challenges in our younger years, we need to solve this dilemma
                    by moving to quadrant III. From younger to older years, we need to build wealth, we need to build
                    our savings and investment lines. The goal is to
                    accumulate enough savings that will support us during our retirement years. With enough savings and
                    investments, we pair it with the right financial knowledge we can then move to quadrant IV. </p>

                <p class="mb-5"><b>4. QIV:</b>in this quadrant having the right financial knowledge and enough savings
                    and investment, we can enjoy a life of zero responsibilities. Why? Because this time, we now let
                    money work for us. How? </p>
                <!-- <em>Example:</em>
                <p>At age 60, we now have 3M pesos. Invest it at 12% per year (in a safe and secure savings or investment program).</p>
                <p>Every year, the 3M will give 360T on interest per year or 30T pesos per month.</p> -->
            </div>
        </div>
    </div>
</section>

<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <p>During our older years, it should be expected that following and understanding the X-curve concept,
                    we should be
                    more secured at this time. The challenge would be:</p>
                <em>what if we live too long and don’t have enough savings? Who will take care of us?</em>
                <p>What if we are not financially educated and we don’t invest our 3M savings? In 3 to 5 years time, it
                    will be
                    depleted and retire broke at age 65 and beyond. Ultimately, the key to wealth building is having the
                    discipline
                    to save and
                    taking the time to learn financial education. Make sure to follow the x-curve concept in wealth
                    building.</p>
            </div>
        </div>
    </div>
</section>
<!-- <section class="money_bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="money_bg_txt">
                    <h4 class="text-center mt-5 mb-5">Money now is working for us.</h4>
                    <p>The 3M is intact, while we receive 30T per month due to the interest alone. Living on Interest.
                    </p>
                    <p>During our older years, it should be expected that following and understanding the X-curve concept, we should be more secured at this time. The challenge would be:</p>
                    <em>what if we live too long and don’t have enough savings? Who will take care of us?</em>
                    <p>What if we are not financially educated and we don’t invest our 3M savings? In 3 to 5 years time, it will be depleted and retire broke at age 65 and beyond. Ultimately, the key to wealth building is having the discipline to save and
                        taking the time to learn financial education. Make sure to follow the x-curve concept in wealth building.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="overlay"></div>
</section> -->