<div class="SubHeader FadIn">
    <h3 class="text-center ">Financial House</h3>
</div>
<section>
    <div class="container">
        <div class="row">
            <div class="col-md-12 mb-5">
                <div class="row">
                    <div class="col-md-4">
                        <div class="financial_img">
                            <img src="./../../assets/images/Financial.png" alt="">
                        </div>
                    </div>
                    <div class="col-md-8 mt-5">
                        <p class="mt-5">A solid financial foundation means one must know his/her priorities. Just like building a house, one must build his financial house with the right foundation. No one builds a house starting from the roof, but from ground up. Here
                            are the five layers in building a solid financial foundation.</p>
                    </div>
                </div>
            </div>

            <div class="row" style="padding: 20px;">
                <div class="col-md-9 mb-4">
                    <h5>1. Protect your Income/ Liabilities (Life Insurance Protection)</h5>
                    <p>Before going into any type of investment, one must first invest in oneself. Life Insurance is the most neglected in United States. While we have not built enough wealth yet, what if something happens to us (esp. parents and breadwinners)?
                        Who will take care of our family? This is where life insurance plays its role. Life insurance isn’t for us, it is for our family. However, life insurance is never a fun topic, that is why most are allergic to it. A lot don’t understand
                        it, a lot don’t want to buy it, and for those who buy, a lot don’t even know their benefits. It is very important to know how to properly choose the right type of insurance that fits our needs.</p>
                </div>
                <div class="col-md-3">
                    <img src="./../../assets/images/Income_Tax.jpg" alt="">
                </div>
            </div>
            <div class="row mb-5" style="padding: 20px;">
                <div class="col-md-3">
                    <img src="./../../assets/images/emergencyfund.jpg" alt="">
                </div>
                <div class="col-md-9 mb-4">
                    <h5>2. Create Emergency Funds</h5>
                    <p>It is quite ironic that a lot of people’s solution in cases of emergencies is “emergency loan.” We shouldn’t go into debt (another problem) to solve a problem. One must develop an emergency fund. Typically, an emergency fund should
                        at least be 3 to 6 months worth of expenses and must be in a very liquid vehicle like banks.</p>
                </div>
            </div>

            <div class="row mb-5" style="padding: 20px;">
                <div class="col-md-9 mb-4">
                    <h5>3. Eliminate Debt</h5>
                    <p>We should target zero debt. With the culture today, debt seems to be a norm. It shouldn’t be that way. A number of wealthy people were asked what is the most important thing that made them wealthy, they answered, “staying out of debt.”
                        We must minimize and eliminate debt.</p>
                </div>
                <div class="col-md-3">
                    <img src="./../../assets/images/macbook-air-on-desk.jpg" alt="">
                </div>
            </div>

            <div class="row mb-5" style="padding: 20px;">
                <div class="col-md-3">
                    <img src="./../../assets/images/investment.jpg" alt="">
                </div>
                <div class="col-md-9 mb-4">
                    <h5>4. Investments (Retirements/ College Savings)</h5>
                    <p>Nobody wants to work for the rest of his/her existence. We need to invest therefore in vehicles that can give us steady income even in our retirement years. Solid investments like businesses, education and properties are traditional
                        and proven ways to build wealth. While liquid investments like stocks, mutual funds, UITF’s, bonds, IUL’s are the modern and also proven ways to build wealth.</p>
                    <p>How does your financial house look like right now? Are you saving the right way or the wrong way?</p>
                </div>
            </div>
        </div>
    </div>
</section>